<template>
  <div class="login-page">
    <div class="error">
      <div class="error__code">
        403
      </div>
      <div class="error__description">
        Вы не имеете права доступа к данному ресурсу
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'forbidden',
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/constants.scss';

  #header-app {
    .login-page {
      .error {
        width: 600px;
        margin: 100px auto 40px;
        color: var(--on-surface-primary-text-color);

        &__code {
          text-align: center;
          font-size: 62px;
          margin-bottom: 20px;
        }

        &__description {
          font-size: 19px;
          text-align: center;
          line-height: 30px;
        }
      }
    }

    @media (max-width: 480px) {
      .login-page {
        .error {
          width: 100%;
          position: absolute;
          margin: 0;
          top: 50%;
          transform: translateY(calc(-50% + #{$header-height} / 2));

          .sign-in-btn {
            margin-top: 20px;
          }
        }
      }
    }
  }
</style>
