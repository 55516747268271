<template>
    <div class="themes">
        <ul class="themes-list">
            <li v-for="(theme, index) in themes"
                :key="index"
                :class="['themes-list__item theme',
                            theme.type,
                            {active: theme.type === activeThemeType}]">
                <div class="theme__header"></div>
                <div class="theme__body">
                    <label class="theme__radio-label">
                        <input :value="theme.type"
                                type="radio"
                                name="theme"
                                @change="onChangeOption(theme.type, $event)"
                                >
                        {{ theme.title }}
                    </label>
                </div>
            </li>
        </ul>
    </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
