import workspacesAPI from '@/api/workspace';

export default {
  namespaced: true,

  state: {
    currentWorkspaceId: undefined,
    workspaces: [],
  },

  getters: {
    notCurrentWorkspaces({ workspaces, currentWorkspaceId }) {
      return workspaces.filter((workspace) => workspace.id !== currentWorkspaceId);
    },
  },

  actions: {
    async getAll({ commit }) {
      const workspaces = await workspacesAPI.getAll();

      commit('setWorkspaces', workspaces);
    },
  },

  mutations: {
    setCurrentWorkspaceId(state, workspaceId) {
      state.currentWorkspaceId = workspaceId;
    },

    setWorkspaces(state, workspaces) {
      state.workspaces = workspaces;
    },
  },
};
