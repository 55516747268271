<template>
  <div class="profile-route">
    <breadcrumb title="Профиль" />

    <div class="profile-route__content">
      <section-menu :items="items"
                    class="profile-route__section-menu" />
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import SectionMenu from '@/components/SectionMenu/SectionMenu.vue';

export default {
  name: 'ProfileView',

  components: {
    breadcrumb: Breadcrumb,
    'section-menu': SectionMenu,
  },

  data() {
    return {
      items: [
        {
          title: 'Основные',
          route: 'personal',
        },
        {
          title: 'Темы оформления',
          route: 'themes',
        },
        {
          title: 'Рабочие периоды',
          route: 'employment-periods',
        },
      ],
    };
  },

  computed: {
    ...mapState('user', ['user']),
  },
};
</script>

<style lang="scss" scoped>
  @import '@mo/tw-uikit/src/styles/palette.scss';

  #header-app {
    .profile-route {
      .settings-btn {
        color: $sky-blue-color-400;
        font-size: 18px;
        text-decoration: none;
        user-select: none;
        text-align: center;
        display: block;

        &:hover {
          color: $sky-blue-color-300;
        }
      }

      &__content {
        max-width: 1220px;
        margin: 20px auto 0;
      }
    }

    @media (max-width: 480px) {
      .profile-route {
        .settings-btn {
          font-size: 16px;
        }

        &__content {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
</style>
