import { jsonApi } from './index';

const employmentPeriodsAPI = {
  getAll(params) {
    return jsonApi.findAll('employment_period', params);
  },

  create(employmentPeriod) {
    return jsonApi.create('employment_period', employmentPeriod);
  },

  edit(employmentPeriod) {
    return jsonApi.update('employment_period', employmentPeriod);
  },

  remove(id) {
    return jsonApi.destroy('employment_period', id);
  },
};

export default employmentPeriodsAPI;
