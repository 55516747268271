import { mapState } from 'vuex';
import { notify } from '@kyvg/vue3-notification';
import { fromISOToLocaleDateString } from '@/services/dateFormat';
import employmentPeriodAPI from '@/api/employment-period';

import ConfirmActionModal from '@/components/ConfirmActionModal/ConfirmActionModal.vue';
import VueButton from '@/components/VueButton/VueButton.vue';

export default {
  components: {
    'confirm-action-modal': ConfirmActionModal,
    'vue-button': VueButton,
  },

  data() {
    return {
      isShowConfirmRemove: false,
      deleteEmploymentPeriodId: null,
    };
  },

  computed: {
    ...mapState('employmentPeriod', ['employmentPeriods']),
  },

  methods: {
    showConfirmRemove(employmentPeriodId) {
      this.deleteEmploymentPeriodId = employmentPeriodId;
      this.isShowConfirmRemove = true;
    },

    cancelRemove() {
      this.isShowConfirmRemove = false;
    },

    async confirmRemove() {
      this.isShowConfirmRemove = false;

      try {
        await employmentPeriodAPI.remove(this.deleteEmploymentPeriodId);

        notify({
          type: 'success',
          group: 'foo',
          title: 'Рабочий период успешно удален',
        });
      } catch (e) {
        notify({
          type: 'error',
          group: 'foo',
          title: 'Ошибка удаления рабочего периода',
        });

        return;
      }

      this.$emit('successfulRemove');
    },

    fromISOToLocaleDateString,
  },
};
