<template>
  <form class="employment-period-form" @submit.prevent="onSubmit">
    <div class="employment-period-form__title">Параметры рабочего периода</div>

    <div :class="['input-group',
                  {'input-group--error': errors.startAt}]">
      <div class="picker-wrapper">
        <label class="required">Начало</label>
        <div class="input-wrapper">
          <date-picker
            v-model="startAt"
            value-type="format"
            format="DD.MM.YYYY"
            name="startAt"
            :editable="!isMobile"
            :popup-style="isMobile ? { 'width': '100%', 'left': 0 } : {}"
            @change="(date) => startAt = date"
          />
          <div class="error">{{ errors.startAt }}</div>
        </div>
      </div>
    </div>

    <div :class="['input-group',
                  {'input-group--error': errors.endAt}]">
      <div class="picker-wrapper">
        <label class="required">Конец</label>
        <div class="input-wrapper">
          <date-picker
            v-model="endAt"
            value-type="format"
            format="DD.MM.YYYY"
            name="endAt"
            :editable="!isMobile"
            :popup-style="isMobile ? { 'width': '100%', 'left': 0 } : {}"
            @change="(date) => endAt = date"
          />
          <div class="error">{{ errors.endAt }}</div>
        </div>
      </div>
    </div>

    <div :class="['input-group',
                  {'input-group--error': errors.workFactor}]">
      <label for="product-employment-period-work-factor">Коэффициент</label>
      <select v-model="workFactor">
        <option value="1" selected>1</option>
        <option value="0.5">0.5</option>
      </select>
      <div class="error">{{ errors.workFactor }}</div>
    </div>

    <vue-button
      :isFlat="false"
      label="Сохранить"
    />
</form>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
