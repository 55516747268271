<template>
  <div class="select-workspace-page">
    <div class="select-workspace-card">
      <div class="select-workspace-card__header">
        Добро пожаловать
      </div>
      <div class="select-workspace-card__body">
        <div class="select-workspace-text">
          Какое рабочее пространство
          <br>
          будет использоваться?
        </div>

        <div class="workspaces">
          <div v-for="workspace in workspaces"
               :key="workspace.id"
               class="workspace"
               @click="selectWorkspace(workspace.id)">
            <div class="workspace__name">
              {{ workspace.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'select-workspace',

  computed: {
    ...mapState('workspace', ['workspaces']),
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store.dispatch('workspace/getAll');
    },

    selectWorkspace(workspaceId) {
      localStorage.setItem('selectedWorkspaceId', workspaceId);
      this.$router.push({ name: 'workspace-home', params: { workspaceId } });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@mo/tw-uikit/src/styles/palette.scss';
  @import '~@/styles/constants.scss';

  #header-app {
    .select-workspace-page {
      display: flex;
      justify-content: center;
      height: calc(100vh - #{$header-height});
      background-image: url('~@/images/main-page-bg.svg');
      background-repeat:no-repeat;
      background-position: center center;
    }

    .select-workspace-card {
      width: 290px;
      margin-top: 87px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0 20px;
        border-radius: 5px 5px 0 0;
        background-color: $sky-blue-color-400;
        color: var(--on-accent-primary-text-color);
        font-size: 16px;
        font-weight: 500;
      }

      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0 0;
        border-radius: 0 0 5px 5px;
        background-color: var(--background-surface-color);
        color: var(--on-surface-primary-text-color);

        .select-workspace-text {
          text-align: center;
          font-size: 12px;
        }

        .workspaces {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 16px 0;
          font-size: 14px;
          font-weight: 500;

          .workspace {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 48px;
            cursor: pointer;

            &__name {
              max-width: calc(100% - 40px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &::before,
            &::after {
              content: '';
            }

            &:not(:first-child)::before {
              width: calc(100% - 40px);
              border-top: 1px solid var(--divider-color);
            }

            &:hover {
              background-color: var(--background-surface-color-a6);
              color: var(--on-surface-anchor-text-color);
            }

            &:active {
              background-color: var(--background-surface-color-a5);
              color: var(--tab-title-text-hover-color);
            }
          }
        }
      }
    }

    @media (max-width: 480px) {
      .select-workspace-page {
        background-image: none;
      }

      .select-workspace-card {
        width: 240px;
      }
    }
  }
</style>
