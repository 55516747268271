import { mapState, mapGetters } from 'vuex';
import InlineSvg from 'vue-inline-svg';
import vClickOutside from 'click-outside-vue3';

import VueButton from '@/components/VueButton/VueButton.vue';
import santaHatSrc from '@/images/santa-hat.svg';

import { PROFILE_SECTION } from '@/constants';

import profileSectionLightIconSrc from './images/profile-section-icon/light.svg';
import profileSectionLightActiveIconSrc from './images/profile-section-icon/light--active.svg';
import profileSectionDarkIconSrc from './images/profile-section-icon/dark.svg';
import profileSectionDarkActiveIconSrc from './images/profile-section-icon/dark--active.svg';
import workspaceLinkIconSvg from './images/workspace-link-icon.svg';
import defaultLogoFullSrc from './images/teamwork-logo/full.svg';
import defaultLogoShortSrc from './images/teamwork-logo/short.svg';

export default {
  name: 'Header',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    'vue-button': VueButton,
    'inline-svg': InlineSvg,
  },

  created() {
    this.$store.dispatch('workspace/getAll');
  },

  data() {
    const christmasTimeStart = new Date().setMonth(11, 1);
    const christmasTimeEnd = new Date().setMonth(0, 21);
    const today = new Date();
    const isChristmasTime = today >= christmasTimeStart || today <= christmasTimeEnd;

    return {
      santaHatSrc,
      workspaceLinkIconSvg,
      isChristmasTime,
      PROFILE_SECTION,
      profileSectionIcons: {
        lightDefault: profileSectionLightIconSrc,
        lightActive: profileSectionLightActiveIconSrc,
        darkDefault: profileSectionDarkIconSrc,
        darkActive: profileSectionDarkActiveIconSrc,
      },
      sectionIconTypes: ['lightDefault', 'lightActive', 'darkDefault', 'darkActive'],
      isShowProfilePopup: false,
    };
  },

  computed: {
    ...mapState('notification', ['messengerNotificationsCount']),
    ...mapState('workspace', ['currentWorkspaceId']),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['userAvatar', 'userInitials', 'workspaceSections']),
    ...mapGetters('workspace', ['notCurrentWorkspaces']),

    activeSectionCode() {
      return this.$route.path.split('/')[3];
    },

    logos() {
      const activeSection = this.workspaceSections.find((section) => (
        section.code === this.activeSectionCode
      ));
      return activeSection?.logos || {
        full: defaultLogoFullSrc,
        short: defaultLogoShortSrc,
      };
    },

    // isMessengerSection() {
    //   return this.activeSection === MESSENGER_SECTION;
    // },

    // isMessengerIconActive() {
    //   return this.isMessengerSection || this.messengerNotificationsCount;
    // },
  },

  methods: {
    goToHomeRoute() {
      if (this.currentWorkspaceId) {
        this.$mfEventBus.emit('enter-workspace', this.currentWorkspaceId);
      } else {
        this.$router.push({ name: 'select-workspace' });
      }
    },

    goToManage() {
      this.toggleProfilePopup();

      this.$mfEventBus.emit('enter-workspace-manage', this.currentWorkspaceId);
    },

    selectWorkspace(workspaceId) {
      this.toggleProfilePopup();

      localStorage.setItem('selectedWorkspaceId', workspaceId);
      this.$router.push({ name: 'workspace-home', params: { workspaceId } });
    },

    toggleProfilePopup(newValue) {
      if (newValue === undefined) {
        newValue = !this.isShowProfilePopup;
      }

      this.isShowProfilePopup = newValue;
    },

    async signOut() {
      this.toggleProfilePopup();

      await this.$store.dispatch('user/signOut');

      this.$router.push({ name: 'login' });
    },
  },
};
