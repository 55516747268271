import { mfStore } from '@teamwork/util';

import authAPI from '@/api/auth';
import usersAPI from '@/api/user';

import { kebabToPascalCase } from '@/helpers';

export default {
  namespaced: true,

  state: {
    isAuth: false,
    user: {},
    workspaceSectionsByApp: mfStore.snapshot.sectionsByApp,

    isAuthenticationCompleted: false,
  },

  getters: {
    userAvatar({ user }) {
      return `https://accounts.motmom.com/users/${user.login}/avatar/big`;
    },

    userInitials({ user }) {
      return `${user.firstName} ${user.lastName}`;
    },

    workspaceSections({ user: { workspace }, workspaceSectionsByApp }) {
      const allSections = [
        ...workspaceSectionsByApp.main,
        ...workspaceSectionsByApp.tasks,
      ];

      return allSections.filter((section) => (
        workspace?.[`is${kebabToPascalCase(section.code)}Enabled`]
      ));
    },
  },

  actions: {
    savePersonalUserSettings({ commit }, { user }) {
      return new Promise((resolve, reject) => {
        usersAPI
          .editCurrent(user)
          .then((localUser) => {
            commit('addUser', localUser);
            resolve();
          })
          .catch(() => { reject(); });
      });
    },

    signOut({ commit }) {
      return authAPI.signOut()
        .then(() => {
          commit('signOut');

          localStorage.removeItem('messenger-auth-token');
        });
    },

    getCurrentUser({ commit }, params) {
      return new Promise((resolve) => {
        usersAPI
          .getCurrent(params)
          .then((user) => {
            commit('signIn', user);
            commit('authenticationCompleted');

            resolve();
          })
          .catch((e) => {
            if (e.status === 400) {
              commit('signIn', {});
            }

            commit('authenticationCompleted');
          });
      });
    },
  },

  mutations: {
    setWorkspaceSectionsByApp(state, workspaceSectionsByApp) {
      state.workspaceSectionsByApp = workspaceSectionsByApp;
    },

    signIn(state, user) {
      state.user = user;
      state.isAuth = true;
    },

    signOut(state) {
      state.user = {};
      state.isAuth = false;
    },

    authenticationCompleted(state) {
      state.isAuthenticationCompleted = true;
    },
  },
};
