<template>
    <header class="header" v-click-outside="() => toggleProfilePopup(false)">
        <div class="header-content">
            <div @click="goToHomeRoute" class="header-content__main">
                <div class="header-content__logo">
                    <template v-if="user.workspace && currentWorkspaceId">
                        <img :src="logos.full" alt="" class="desktop-logo">
                        <img :src="logos.short" alt="" class="mobile-logo">
                    </template>

                    <template v-else>
                        <img :src="logos.full" alt="">
                    </template>

                    <img :src="santaHatSrc" alt=""
                         v-if="isChristmasTime"
                         class="santa-hat">
                </div>

                <div v-if="user.workspace && currentWorkspaceId" class="current-workspace-name">
                    {{ user.workspace.name }}
                </div>
            </div>

            <template v-if="user.workspace && currentWorkspaceId">
                <div class="header-content__additions">
                    <div class="navigation">
                        <div v-for="section in workspaceSections"
                             :key="section.code"
                             @click="$mfEventBus.emit('enter-workspace-section', {
                               workspaceId: currentWorkspaceId,
                               section: section.code,
                             })"
                             @click.middle="$mfEventBus.emit('enter-workspace-section-blank', {
                               workspaceId: currentWorkspaceId,
                               section: section.code,
                             })"
                             :class="['navigation__item', {
                               'navigation__item--active': activeSectionCode === section.code,
                             }]">
                            <img v-for="iconType in sectionIconTypes"
                                 :key="`${section.code}-${iconType}`"
                                 :src="section.icons[iconType]"
                                 alt=""
                                 :class="`icon icon--${iconType}`">

<!--                            <span v-if="section === MESSENGER_SECTION"-->
<!--                                  v-show="messengerNotificationsCount"-->
<!--                                  :class="[-->
<!--                                    'messenger__counter',-->
<!--                            { 'messenger__counter&#45;&#45;active': isMessengerIconActive }-->
<!--                                  ]">-->
<!--                              {{messengerNotificationsCount}}-->
<!--                            </span>-->
                        </div>

                        <div :class="['navigation__item', 'navigation__item--mobile', {
                               'navigation__item--active': (
                                 isShowProfilePopup || activeSectionCode === PROFILE_SECTION
                               ),
                             }]"
                             @click="toggleProfilePopup()">
                            <img v-for="(iconSrc, iconType) in profileSectionIcons"
                                 :key="`profile-${iconType}`"
                                 :src="iconSrc"
                                 alt=""
                                 :class="`icon icon--${iconType}`">
                        </div>

                        <div class="profile-popup"
                             v-if="isShowProfilePopup">
                            <div class="profile-popup__header">
                                <img class="user-avatar" :src="userAvatar" alt="">

                                <router-link :to="{
                                                 name: `${PROFILE_SECTION}-section`,
                                                 params: { workspaceId: currentWorkspaceId },
                                             }"
                                             @click="toggleProfilePopup(false)"
                                             class="profile-link">
                                    <div class="user-initials">
                                        {{userInitials}}
                                    </div>

                                    <div class="user-email">
                                        {{user.email}}
                                    </div>

                                    <div class="arrow" />
                                </router-link>
                            </div>

                            <div class="profile-popup__body">
                                <div class="links">
                                    <div v-for="workspace in notCurrentWorkspaces"
                                         :key="workspace.id"
                                         @click="selectWorkspace(workspace.id)"
                                         class="link workspace">
                                        <div class="workspace__name">
                                            {{workspace.name}}
                                        </div>

                                        <inline-svg class="workspace__icon"
                                                    :src="workspaceLinkIconSvg" />
                                    </div>

                                    <div @click="goToManage"
                                                 v-if="user.isAdmin"
                                                 class="link manage">
                                        Администрирование
                                    </div>

                                    <div class="link sign-out" @click="signOut">
                                      Выйти
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="header-content__avatar">
                    <img :src="userAvatar"
                         alt=""
                         @click="toggleProfilePopup()">
                </div>
            </template>
        </div>
    </header>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
