export default class DynamicFavicon {
  static WIDTH = 32;

  static HEIGHT = 32;

  static FONT = '18px Montserrat';

  static BACKGROUND_COLOR = '#FA5D72';

  static TEXT_COLOR = '#ffffff';

  constructor() {
    this.faviconImage = new Image();
    [this.faviconEl] = document.head.getElementsByClassName('favicon');
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');

    this.init();
  }

  init() {
    this.faviconImage.src = this.faviconEl.href;

    this.canvas.width = DynamicFavicon.WIDTH;
    this.canvas.height = DynamicFavicon.HEIGHT;

    this.ctx.font = DynamicFavicon.FONT;
  }

  draw(text) {
    this.ctx.clearRect(
      0,
      0,
      DynamicFavicon.WIDTH,
      DynamicFavicon.HEIGHT,
    );

    this.ctx.drawImage(
      this.faviconImage,
      0,
      0,
      DynamicFavicon.WIDTH,
      DynamicFavicon.HEIGHT,
    );

    if (!text) {
      this.faviconEl.href = this.canvas.toDataURL('image/x-icon');

      return;
    }

    const { actualBoundingBoxAscent, width: textWidth } = this.ctx.measureText(text);

    const arcX = DynamicFavicon.WIDTH / 1.65;
    const arcY = DynamicFavicon.HEIGHT / 2.55;
    const arcSize = DynamicFavicon.WIDTH / 2.6;

    this.ctx.fillStyle = DynamicFavicon.BACKGROUND_COLOR;
    this.ctx.beginPath();
    this.ctx.arc(
      arcX,
      arcY,
      arcSize,
      0,
      Math.PI * 2,
      false,
    );
    this.ctx.fill();
    this.ctx.closePath();

    this.ctx.fillStyle = DynamicFavicon.TEXT_COLOR;
    this.ctx.fillText(
      text,
      (arcX) - (textWidth / 2),
      arcSize + (actualBoundingBoxAscent / 2),
    );

    this.faviconEl.href = this.canvas.toDataURL('image/x-icon');
  }
}
