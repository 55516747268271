function isInScope(eventScope, handlerScope) {
  return Object.entries(handlerScope).every(([key, expectedValue]) => {
    if (!(key in eventScope)) {
      return false;
    }

    const value = eventScope[key];

    if (!Array.isArray(value)) {
      return value === expectedValue;
    }

    return Array.isArray(expectedValue) && expectedValue.every((item) => value.includes(item));
  });
}

function isScopedData(data) {
  return (
    typeof data === 'object'
    && data !== null
    && 'scope' in data
    && typeof data.scope === 'object'
    && data.scope !== null
  );
}

function makeListener(handler, options) {
  return ({ data }) => {
    const parsedData = JSON.parse(data);
    const eventScope = isScopedData(parsedData) ? parsedData.scope : undefined;

    if (!eventScope || !options?.scope || isInScope(eventScope, options.scope)) {
      handler(parsedData);
    }
  };
}

export default class SseManager {
  eventSource = null;

  constructor(url) {
    if (window.EventSource) {
      this.eventSource = new EventSource(url);
    }
  }

  add(type, handler, options) {
    return this.addMany({ [type]: handler }, options);
  }

  addMany(handlerByType, options) {
    const listenerByTypes = {};

    if (this.eventSource) {
      Object.entries(handlerByType).forEach(([type, handler]) => {
        const listener = makeListener(handler, options);
        listenerByTypes[type] = listener;

        this.eventSource.addEventListener(type, listener);
      });
    }

    return () => Object.entries(listenerByTypes).forEach(([type, listener]) => {
      this.eventSource.removeEventListener(type, listener);
    });
  }
}
