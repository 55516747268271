import { DATE_CONST } from '@/constants';

// eslint-disable-next-line
export const fullDateToNumeric = (date) => {
  const newDate = new Date(date).toLocaleDateString(DATE_CONST.LOCALE);

  return `${newDate.slice(6)}-${newDate.slice(3, 5)}-${newDate.slice(0, 2)}`;
};

export function fromISOToLocaleDateString(dateString, locales = 'ru-RU') {
  if (dateString === null) {
    return null;
  }
  return new Date(dateString).toLocaleDateString(locales);
}

export function fromRULocaleDateStringToISO(dateString) {
  if (dateString === null) {
    return null;
  }
  return new Date(dateString.split('.').reverse().join('-')).toISOString().split('T')[0];
}
