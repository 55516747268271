import employmentPeriodAPI from '@/api/employment-period';

export default {
  namespaced: true,

  state: {
    employmentPeriods: [],
  },

  getters: {
    getById: (state) => (id) => state.employmentPeriods.find(
      (employmentPeriod) => employmentPeriod.id === id,
    ),
  },

  actions: {
    async getAll({ commit }) {
      const employmentPeriods = (await employmentPeriodAPI.getAll()).data;

      commit('setEmploymentPeriods', employmentPeriods);
    },
  },

  mutations: {
    setEmploymentPeriods(state, employmentPeriods) {
      state.employmentPeriods = employmentPeriods;
    },
  },
};
