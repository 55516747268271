<template>
  <modal-window @close="cancel" v-click-outside="cancel">
    <template v-slot:content>
      <div class="confirm-window">
        <div name="title" class="confirm-window__title">{{ title }}</div>

        <div class="confirm-window__body">{{ body }}</div>

        <div class="confirm-window__buttons">
          <button
            class="confirm-window__cancel-button"
            @click="cancel"
          >
            {{ cancelText }}
          </button>

          <button
            class="confirm-window__confirm-button"
            @click="confirm"
          >
            {{ confirmText }}
          </button>
        </div>
      </div>
    </template>
  </modal-window>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
