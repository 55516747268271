<template>
  <div class="modal-window-wrapper">
      <div class="modal-window">
          <div class="modal-window__close-icon" @click="close"></div>
          <div class="modal-window__content">
              <slot name="content">
                  default content
              </slot>
          </div>
      </div>
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
