export default {
  namespaced: true,

  state: {
    messengerNotificationsCount: 0,
  },

  mutations: {
    setMessengerNotificationsCount(state, amount) {
      state.messengerNotificationsCount = amount;
    },
  },
};
