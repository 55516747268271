import { mapState, mapGetters } from 'vuex';
import { object, string } from 'yup';
import { useField, useForm } from 'vee-validate';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { notify } from '@kyvg/vue3-notification';

import { fromRULocaleDateStringToISO, fromISOToLocaleDateString } from '@/services/dateFormat';
import employmentPeriodAPI from '@/api/employment-period';

import VueButton from '@/components/VueButton/VueButton.vue';

const TYPES = {
  update: 'update',
  create: 'create',
};

const NOTIFY_PARAMS = {
  create: {
    type: 'success',
    group: 'foo',
    title: 'Рабочий период успешно создан',
  },
  update: {
    type: 'success',
    group: 'foo',
    title: 'Рабочий период успешно изменен',
  },
  error: {
    type: 'error',
    group: 'foo',
    title: 'Ошибка сохранения рабочего периода. Перезагрузите страницу и попробуйте снова.',
  },
};

const validationSchema = object({
  startAt: string().required(),
  endAt: string().nullable(),
  workFactor: string().required(),
});

const presetValidation = () => {
  const formValues = {
    startAt: '',
    endtAt: '',
    workFactor: '1',
  };

  const { errors, validate, setValues } = useForm({
    initialValues: formValues,
    validationSchema,
  });

  const fields = {
    startAt: useField('startAt').value,
    endAt: useField('endAt').value,
    workFactor: useField('workFactor').value,
  };

  return {
    errors,
    validate,
    setValues,
    ...fields,
  };
};

export default {
  components: {
    'vue-button': VueButton,
    'date-picker': DatePicker,
  },

  props: {
    id: String,
  },

  setup() {
    const validationData = presetValidation();

    return {
      ...validationData,
    };
  },

  data() {
    return {
      type: this.id ? TYPES.update : TYPES.create,
    };
  },

  computed: {
    ...mapState('ui', ['isMobile']),
    ...mapState('employmentPeriod', ['isLoaded']),
    ...mapGetters('employmentPeriod', ['getById']),

    employmentPeriod() {
      if (this.type === TYPES.update) {
        return this.getById(this.id);
      }

      return undefined;
    },
  },

  watch: {
    employmentPeriod: {
      immediate: true,
      handler() {
        if (this.employmentPeriod) {
          this.setValues({
            startAt: fromISOToLocaleDateString(this.employmentPeriod.startAt),
            endAt: fromISOToLocaleDateString(this.employmentPeriod.endAt),
            workFactor: this.employmentPeriod.workFactor,
          });
        }
      },
    },
  },

  methods: {
    async onSubmit() {
      const { valid } = await this.validate();

      if (!valid) {
        return;
      }

      const startAt = fromRULocaleDateStringToISO(this.startAt);
      const endAt = this.endAt ? fromRULocaleDateStringToISO(this.endAt) : null;

      const data = {
        ...this.employmentPeriod,
        startAt,
        endAt,
        workFactor: this.workFactor,
      };

      try {
        if (this.type === TYPES.create) {
          await employmentPeriodAPI.create(data);
        } else {
          await employmentPeriodAPI.edit(data);
        }

        this.$emit('successfulSave');

        notify(NOTIFY_PARAMS[this.type]);
      } catch (e) {
        notify(NOTIFY_PARAMS.error);
      }
    },
  },
};
