<template>
    <div class="personal">
        <aside class="sidebar">
            <div class="sidebar__avatar">
                <img :src="userAvatar" alt="">
            </div>
        </aside>
        <form class="personal__form" v-if="user" @submit.prevent="onSubmit">
            <div :class="['input-group',
                          {'input-group--error': errors.lastName}]">
                <label class="required" for="last_name">Фамилия</label>
                <input id="last_name" type="text" name="last_name"
                       v-model="lastName">
                <div>{{ errors.lastName }}</div>
            </div>

            <div :class="['input-group',
                          {'input-group--error': errors.firstName}]">
                <label class="required" for="first_name">Имя</label>
                <input id="first_name" type="text" name="first_name"
                       v-model="firstName">
                <div>{{ errors.firstName }}</div>
            </div>

            <div :class="['input-group',
                          {'input-group--error': errors.email}]">
                <label class="required" for="email">Рабочий e-mail</label>
                <input id="email" type="email" name="email"
                       v-model="email">
                <div>{{ errors.email }}</div>
            </div>

            <div :class="['input-group',
                          {'input-group--error': errors.phone}]">
                <label for="phone">Номер телефона</label>

                <input type="tel"
                       id="phone"
                       name="phone"
                       v-model="phone"
                       ref="phoneInput" />
              <div>{{ errors.phone }}</div>
            </div>

            <div :class="['input-group',
                          'input-group--locked']"
                 v-if="user.team">
                <label for="team">Команда</label>
                <input id="team" type="text" name="team"
                       :value="user.team.name"
                       disabled>
            </div>

            <div class="input-group">
                <label for="jira-login">Логин Jira</label>
                <input id="jira-login" type="text" name="jira-login"
                       v-model="user.jiraLogin">
            </div>

            <div class="input-group">
                <label for="messenger-chat-id">Id чата в Mo.Messenger</label>
                <input id="messenger-chat-id" type="text" name="messenger-chat-id"
                       v-model="user.messengerChatId">
            </div>

            <vue-button :isFlat="true"
                        label="Сохранить изменения" />
        </form>
    </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
