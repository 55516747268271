<template>
  <modal-window @close="goToEmploymentPeriodsList">
      <template v-slot:content>
          <employment-period-form
            :id="id"
            @successfulSave="goToEmploymentPeriodsList"
          >
          </employment-period-form>
      </template>
  </modal-window>
</template>

<script>
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue';
import EmploymentPeriodForm from '@/components/Profile/EmploymentPeriods/EmploymentPeriodForm/EmploymentPeriodForm.vue';

export default {
  name: 'ProductsAdd',

  components: {
    'modal-window': ModalWindow,
    'employment-period-form': EmploymentPeriodForm,
  },

  data() {
    return {
      id: this.$route.params.id,
    };
  },

  methods: {
    goToEmploymentPeriodsList() {
      this.$router.push({ name: 'employment-periods' });
    },
  },
};
</script>
