export function getUserAvatar(userLogin) {
  return `https://accounts.motmom.com/users/${userLogin}/avatar/big`;
}

export function hexToOpacity(hexColor) {
  let opacityPart;

  if (hexColor.length <= 5) {
    opacityPart = hexColor.slice(4).repeat(2);
  } else {
    opacityPart = hexColor.slice(7);
  }

  return (Number.parseInt(opacityPart, 16) || 255) / 255;
}

export function pluralize(itemsCount, rules) {
  let number = itemsCount;

  number %= 100;

  if (number >= 5 && number <= 20) {
    return rules[5];
  }

  number %= 10;

  if (number === 1) {
    return rules[1];
  }

  if (number >= 2 && number <= 4) {
    return rules[2];
  }

  return rules[5];
}

export function getDaysInMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export function isNotPastMonth(date) {
  if (date) {
    const checkDate = new Date(date);
    const today = new Date();
    return checkDate.getFullYear() >= today.getFullYear()
      && checkDate.getMonth() >= today.getMonth();
  }
  return true;
}

export function getPassedTimeForDate(date) {
  const day = new Date(date);
  const today = new Date();
  const diff = today - day;

  let s = Math.floor(diff / 1000);

  let m = Math.floor(s / 60);
  s %= 60;

  let h = Math.floor(m / 60);
  m %= 60;

  const d = Math.floor(h / 24);
  h %= 24;

  return [d, h, m, s];
}

export function getDaysCountBetweenDates(date1, date2) {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  const diff = secondDate - firstDate;

  const s = Math.floor(diff / 1000);
  const m = Math.floor(s / 60);
  const h = Math.floor(m / 60);
  return Math.floor(h / 24);
}

export class DefaultDict {
  constructor(DefaultInit) {
    return new Proxy({}, {
      get(target, name) {
        target[name] = name in target ? target[name] : new DefaultInit();
        return target[name];
      },
    });
  }
}

export function kebabToPascalCase(str) {
  return str.replace(/((^|-)[a-z])/g, (group) => group.replace('-', '').toUpperCase());
}
