import vClickOutside from 'click-outside-vue3';

export default {
  methods: {
    close() {
      this.$emit('close');
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },
};
