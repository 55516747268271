<template>
  <div class="personal-route">
    <personal-form />

    <router-view />
  </div>
</template>

<script>
import PersonalForm from '@/components/Profile/PersonalForm/PersonalForm.vue';

export default {
  name: 'PersonalView',

  components: {
    'personal-form': PersonalForm,
  },
};
</script>
