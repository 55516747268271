import vClickOutside from 'click-outside-vue3';
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue';

export default {
  components: {
    'modal-window': ModalWindow,
  },

  props: {
    title: {
      type: String,
      default: 'Подтвердите действие',
    },

    body: {
      type: String,
      default: '',
    },

    cancelText: {
      type: String,
      default: 'Отмена',
    },

    confirmText: {
      type: String,
      default: 'Принять',
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },

    confirm() {
      this.$emit('confirm');
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },
};
