<template>
    <div class="modal-section-menu-wrapper">
        <div class="modal-section-menu" v-click-outside="close">
            <div class="modal-section-menu__content">
                <slot name="content">
                    default content
                </slot>
            </div>
        </div>
    </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
