import { createApp } from 'vue/dist/vue.esm-bundler';
import Notifications from '@kyvg/vue3-notification';
import Maska from 'maska';
import 'normalize.css';

import { mfEventBus, mfStore } from '@teamwork/util';

import App from './App.vue';
import router from './router';
import store from './store';
import { eventBus } from './services';

import './styles/index.scss';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(Notifications);
app.use(Maska);
app.config.globalProperties.$eventBus = eventBus;
app.config.globalProperties.$mfEventBus = mfEventBus;
app.config.globalProperties.$mfStore = mfStore;
app.mount('#app');
