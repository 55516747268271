import axios from 'axios';
import JsonApi from 'devour-client';

import router from '@/router';
import store from '@/store';

const instance = axios.create({
  headers: { Accept: '*/*' },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    const currentRouteName = router.currentRoute.value.name;

    if (response.status === 401 && currentRouteName !== 'login') {
      router.push({ name: 'login' });
    }

    if (response.status === 400 && currentRouteName !== 'select-workspace') {
      router.push({ name: 'select-workspace' });
    }

    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  (config) => {
    const workspaceId = store.state.workspace.currentWorkspaceId;
    if (workspaceId) {
      config.headers['X-Workspace-Id'] = workspaceId;
    }
    return config;
  },
);

const jsonApi = new JsonApi({ apiUrl: '/api' });

const requestMiddleware = {
  name: 'add-workspace-id-to-request-headers',
  req: (payload) => {
    const workspaceId = store.state.workspace.currentWorkspaceId;
    if (workspaceId) {
      payload.req.headers['X-Workspace-Id'] = workspaceId;
    }
    return payload;
  },
};

const errorMiddleware = {
  name: 'json-api-error',
  error: (payload) => {
    const currentRouteName = router.currentRoute.value.name;

    if (payload.response.status === 401 && currentRouteName !== 'login') {
      router.push({ name: 'login' });
    }

    if (payload.response.status === 400 && currentRouteName !== 'select-workspace') {
      router.push({ name: 'select-workspace' });
    }

    return Promise.reject(payload.response);
  },
};

jsonApi.replaceMiddleware('errors', errorMiddleware);
jsonApi.insertMiddlewareBefore('axios-request', requestMiddleware);

jsonApi.define(
  'workspace',
  {
    name: '',
    isCheckInEnabled: false,
    isBalanceEnabled: false,
    isShopEnabled: false,
    isMessengerEnabled: false,
    isTasksEnabled: false,

    workIntervals: {
      jsonApi: 'hasMany',
      type: 'work_intervals',
    },
  },
);

jsonApi.define(
  'work_interval',
  {
    startAt: '',
    endAt: '',
  },
);

jsonApi.define(
  'team',
  {
    name: '',
    color: '',
    flag: '',
    emblem: '',
    issuesServiceType: '',
    isInactive: '',

    users: {
      jsonApi: 'hasMany',
      type: 'user',
    },
  },
);

jsonApi.define(
  'employment_period',
  {
    startAt: '',
    endAt: '',
    workFactor: '',
  },
  {
    collectionPath: 'employment-periods',
  },
);

jsonApi.define(
  'user',
  {
    teamId: '',
    messengerChatId: '',
    email: '',
    firstName: '',
    isAdmin: '',
    isInactive: '',
    isMale: '',
    lastName: '',
    login: '',
    phone: '',
    turnstileId: '',
    jiraLogin: '',

    team: {
      jsonApi: 'hasOne',
      type: 'teams',
    },

    workspace: {
      jsonApi: 'hasOne',
      type: 'workspaces',
    },

    employment_periods: {
      jsonApi: 'hasMany',
      type: 'employment_period',
    },
  },
);

export { instance, jsonApi };
