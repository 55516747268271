<template>
    <div class="breadcrumb" :class="{ 'breadcrumb--wrapped': wrapAside }">
        <div class="breadcrumb-content">
            <div class="breadcrumb-content__title" v-if="title">
                <span>{{ title }}</span>

                <slot name="title-addition"></slot>
            </div>

            <div class="breadcrumb-content__aside">
                <slot name="aside"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Header',

  props: {
    title: String,
    wrapAside: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mo/tw-uikit/src/styles/palette.scss';

#header-app {
    .breadcrumb {
        height: 58px;
        background-color: var(--subheader-color);

        .breadcrumb-content {
            max-width: 1220px;
            margin: auto;
            padding: 11px 10px;
            box-sizing: border-box;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
                display: flex;
                align-items: center;
                font-size: 18px;
                color: var(--on-surface-primary-text-color);
                line-height: 22px;
                font-weight: bold;
            }
        }
    }

    @media (max-width: 480px) {
        .breadcrumb {
            height: 70px;

            .breadcrumb-content {
                width: 100%;
                padding: 10px 16px 10px 24px;

                &__title {
                    width: auto;
                    font-size: 16px;

                    & > span {
                        width: 100%;
                        text-align: center;
                    }

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &__aside {
                    flex-shrink: 0;
                }
            }

            &--wrapped {
                height: auto;

                .breadcrumb-content {
                    display: flex;
                    flex-direction: column;
                    height: auto;

                    &__title {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: calc(100% - 20px);
                        padding-top: 10px;
                    }

                    &__aside {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
