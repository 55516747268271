import vClickOutside from 'click-outside-vue3';

export default {
  name: 'ModalSectionMenu',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
