import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { computed } from 'vue';
import { useStore, mapGetters } from 'vuex';
import { useForm, useField } from 'vee-validate';
import { object, string } from 'yup';
import { notify } from '@kyvg/vue3-notification';
import VueButton from '@/components/VueButton/VueButton.vue';

const validationSchema = object({
  lastName: string().required(),
  firstName: string().required(),
  email: string().email().required(),
  phone: string().nullable(),
});

const presetValidation = (user) => {
  const formValues = {
    lastName: user.value.lastName,
    firstName: user.value.firstName,
    email: user.value.email,
    phone: user.value.phone,
  };

  const { errors, validate } = useForm({
    initialValues: formValues,
    validationSchema,
  });

  const fields = {
    lastName: useField('lastName').value,
    firstName: useField('firstName').value,
    email: useField('email').value,
    phone: useField('phone').value,
  };

  return {
    errors,
    validate,
    ...fields,
  };
};

export default {
  name: 'Personal',

  components: {
    'date-picker': DatePicker,
    'vue-button': VueButton,
  },

  setup() {
    const store = useStore();
    const user = computed(() => store.state.user.user);
    const validationData = presetValidation(user);

    return {
      ...validationData,
      user,
    };
  },
  watch: {
    phone(newValue) {
      const cursorPosition = this.$refs.phoneInput?.selectionStart;

      if (!newValue) {
        return;
      }

      this.phone = `${(newValue && newValue.length) ? '+' : ''}${newValue.replace(/[^\d\-()]+/g, '')}`;

      if (newValue && newValue.match(/[^\d\-()+]+/g)) {
        this.$nextTick(() => {
          this.$refs.phoneInput?.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
        });
      }
    },
  },

  computed: {
    ...mapGetters('user', ['userAvatar']),
  },

  methods: {
    async onSubmit() {
      const { valid } = await this.validate();

      if (!valid) {
        return;
      }

      const data = {
        lastName: this.lastName,
        firstName: this.firstName,
        email: this.email,
        phone: this.phone,
        jiraLogin: this.user.jiraLogin,
        messengerChatId: this.user.messengerChatId,
      };
      this.$store
        .dispatch('user/savePersonalUserSettings', { user: data })
        .then(
          () => {
            notify({
              type: 'success',
              group: 'foo',
              title: 'Персональные данные успешно сохранены',
            });
          },
          () => {
            notify({
              type: 'error',
              group: 'foo',
              title: 'Ошибка сохранения персональных данных. Перезагрузите страницу и попробуйте снова.',
            });
          },
        );
    },
  },
};
