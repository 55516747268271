<template>
    <button :class="buttonClass">
        {{label}}

        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </button>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
