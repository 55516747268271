import { createStore } from 'vuex';
import { mfStore } from '@teamwork/util';

import employmentPeriod from '@/modules/employment-period';
import notification from '@/modules/notification';
import ui from '@/modules/ui';
import user from '@/modules/user';
import workspace from '@/modules/workspace';

const store = createStore({
  modules: {
    employmentPeriod,
    notification,
    ui,
    user,
    workspace,
  },
});

mfStore.subscribe(mfStore.state.sectionsByApp, () => {
  store.commit('user/setWorkspaceSectionsByApp', mfStore.snapshot.sectionsByApp);
});

export default store;
