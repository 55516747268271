export const DATE_CONST = {
  LOCALE: 'ru',
  WEEK_DAY: { weekday: 'long' },
  DAY_MONTH: {
    month: 'long',
    day: 'numeric',
  },
  PREVIOUS: 'previous',
  NEXT: 'next',
  MONTH_WITH_YEAR: { month: 'long' },
  HOURS: 3600000,
  VACATION_DAY_HALF_DELIMITER: '17:00',
};

export const THEMES = [
  {
    id: 1,
    type: 'light',
    title: 'Светлая тема',
  },
  {
    id: 2,
    type: 'dark',
    title: 'Темная тема',
  },
  {
    id: 3,
    type: 'automatic',
    title: 'Автоматически',
  },
];

export const MOBILE_BREAKPOINT = 480;

export const EVENT_WORKSPACE_UPDATE = 'workspace-update';

export const PROFILE_SECTION = 'profile';

export const EMPLOYMENT_PERIODS_ROUTE_NAME = 'employment-periods';
