const DEFAULT = 'btn';
const FLAT = 'btn-flat';

export default {
  name: 'VueButton',

  props: {
    customClass: {
      type: String,
      default: '',
    },
    isFlat: Boolean,
    isLoading: Boolean,
    isClickMe: Boolean,
    label: String,
  },

  computed: {
    buttonClass() {
      const btnClass = this.isFlat ? FLAT : DEFAULT;

      return {
        [`${btnClass}--loading`]: this.isLoading,
        [`${btnClass}--click-me`]: this.isClickMe,
        [btnClass]: true,
        [this.customClass]: true,
      };
    },
  },
};
