import ModalSectionMenu from '@/components/ModalSectionMenu/ModalSectionMenu.vue';

export default {
  name: 'SectionMenu',

  components: {
    'modal-section-menu': ModalSectionMenu,
  },

  data() {
    return {
      isVisibleSectionMenu: false,
    };
  },

  props: {
    items: Array,
    customWidth: Boolean,
  },

  beforeRouteUpdate(to, from, next) {
    this.closeSectionMenu();

    next();
  },

  methods: {
    openSectionMenu() {
      this.isVisibleSectionMenu = true;
    },

    closeSectionMenu() {
      this.isVisibleSectionMenu = false;
    },
  },
};
