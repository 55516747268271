import { THEMES } from '@/constants';

export default {
  name: 'PersonalThemes',

  data() {
    const themes = THEMES;

    return {
      activeThemeType: localStorage.getItem('activeThemeType') || themes[0].type,
      themes,
    };
  },

  methods: {
    onChangeOption(type) {
      const htmlElement = document.documentElement;
      this.activeThemeType = type;

      localStorage.setItem('activeThemeType', this.activeThemeType);
      htmlElement.setAttribute('theme', this.activeThemeType);
    },
  },
};
