import { createRouter, createWebHashHistory } from 'vue-router';

import { mfEventBus, mfStore } from '@teamwork/util';

import Login from '@/views/Login.vue';
import Forbidden from '@/views/Forbidden.vue';
import SelectWorkspace from '@/views/SelectWorkspace.vue';
import Personal from '@/views/Profile/Personal/Personal.vue';
import Profile from '@/views/Profile/Profile.vue';
import Themes from '@/views/Profile/Themes/Themes.vue';
import EmploymentPeriods from '@/views/Profile/EmploymentPeriods/EmploymentPeriods.vue';
import EmploymentPeriod from '@/views/Profile/EmploymentPeriods/EmploymentPeriod.vue';

import store from '@/store';
import {
  PROFILE_SECTION,
  EMPLOYMENT_PERIODS_ROUTE_NAME,
} from '@/constants';
import { eventBus } from './services';

// Пустые компоненты добавлены к роутам,
// которые перехватываются роутером другого приложения
const Empty = { template: '<div></div>' };
const EmptyParent = { template: '<router-view></router-view>' };

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: Empty,
    },

    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: 'Авторизация' },
      beforeEnter: () => {
        if (store.state.user.isAuth) {
          return { name: 'select-workspace' };
        }

        return true;
      },
    },

    {
      path: '/forbidden',
      name: 'forbidden',
      component: Forbidden,
      meta: { title: 'Доступ запрещен' },
    },

    {
      path: '/select-workspace',
      name: 'select-workspace',
      component: SelectWorkspace,
      meta: { title: 'Выбор рабочего пространства', requiresAuth: true },
    },

    {
      path: '/ws/:workspaceId',
      name: 'workspace-home',
      component: EmptyParent,
      meta: { requiresWorkspace: true },

      children: [
        // Личный кабинет
        // Профиль пользователя
        {
          path: PROFILE_SECTION,
          name: `${PROFILE_SECTION}-section`,
          redirect: { name: 'personal' },
          component: Profile,

          children: [
            {
              path: 'personal',
              name: 'personal',
              component: Personal,
              meta: { title: 'Личный кабинет' },
            },

            {
              path: 'themes',
              name: 'themes',
              component: Themes,
              meta: { title: 'Темы оформления' },
            },

            {
              path: 'employment-periods',
              name: EMPLOYMENT_PERIODS_ROUTE_NAME,
              component: EmploymentPeriods,
              meta: { title: 'Рабочие периоды' },

              children: [
                {
                  path: 'add',
                  name: 'add-employment-period',
                  component: EmploymentPeriod,
                  meta: { title: 'Добавление рабочего периода' },
                },

                {
                  path: ':id/edit',
                  name: 'edit-employment-period',
                  component: EmploymentPeriod,
                  meta: { title: 'Редактирование рабочего периода' },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

const WS_PATH = '^/ws/(?<workspaceId>\\d+)(/.+)?';
router.beforeEach(async (to) => {
  const { user: userState, workspace: wsState } = store.state;

  if (to.fullPath === '/') {
    const workspaceId = localStorage.getItem('selectedWorkspaceId');
    if (workspaceId) {
      return { name: 'workspace-home', params: { workspaceId } };
    }
  }

  const workspaceId = to.fullPath.match(WS_PATH)?.groups?.workspaceId;
  const isNewWorkspaceId = workspaceId !== wsState.currentWorkspaceId;

  if (isNewWorkspaceId) {
    store.commit('workspace/setCurrentWorkspaceId', workspaceId);
  }

  if (isNewWorkspaceId || !userState.isAuthenticationCompleted) {
    await store.dispatch(
      'user/getCurrentUser',
      { include: 'workspace.work_intervals,team.users' },
    );
    mfStore.state.user = userState.user;
    eventBus.emit('user-fetched');
  }

  for (const route of to.matched) {
    if (route.meta.requiresAuth && !userState.isAuth) {
      return { name: 'login' };
    }

    if (route.meta.requiresWorkspace && !userState.user.workspace) {
      return { name: 'forbidden' };
    }
  }

  if (to.name) {
    document.title = to.meta.title || 'Teamwork';
  }

  return true;
});

mfEventBus.on('unauthorized-error', () => {
  router.push({ name: 'login' });
});
mfEventBus.on('forbidden-error', () => {
  router.push({ name: 'forbidden' });
});

export default router;
