<template>
  <div class="login-page">
    <div class="error">
      <div class="error__code">
        401
      </div>
      <div class="error__description">
        Отсутствует авторизация.
        Для доступа к ресурсу требуется аутентификация.
      </div>

      <div class="sign-in-btn">
          <vue-button :isFlat="false"
                      label="Войти"
                      v-on:click="signIn"/>
      </div>
    </div>
  </div>
</template>

<script>
import VueButton from '@/components/VueButton/VueButton.vue';

export default {
  name: 'login',

  components: {
    'vue-button': VueButton,
  },

  methods: {
    signIn() {
      // eslint-disable-next-line
      location = '/api/auth/sign-in';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/constants.scss';

  #header-app {
    .login-page {
      .error {
        width: 600px;
        margin: 100px auto 40px;
        color: var(--on-surface-primary-text-color);

        &__code {
          text-align: center;
          font-size: 62px;
          margin-bottom: 20px;
        }

        &__description {
          font-size: 19px;
          text-align: center;
          line-height: 30px;
          margin-bottom: 20px;
        }

        .sign-in-btn {
          text-align: center;
        }
      }
    }

    @media (max-width: 480px) {
      .login-page {
        .error {
          width: 100%;
          position: absolute;
          margin: 0;
          top: 50%;
          transform: translateY(calc(-50% + #{$header-height} / 2));
        }
      }
    }
  }
</style>
