import { instance, jsonApi } from './index';

const usersAPI = {
  create(user) {
    return jsonApi.create('user', user);
  },

  edit(user) {
    return jsonApi.update('user', user);
  },

  getAll(params) {
    return jsonApi.findAll('user', params);
  },

  getById(id, params) {
    return jsonApi.find('user', id, params);
  },

  getInfoByEmail(email) {
    return new Promise((resolve, reject) => {
      instance.post('/api/users/get-user-info', { userEmail: email })
        .then(({ data }) => {
          const user = jsonApi.deserialize.resource.call(jsonApi, data.data, data.included);

          resolve(user);
        })
        .catch(() => { reject(); });
    });
  },

  getCurrent(params) {
    return new Promise((resolve, reject) => {
      jsonApi.request('/api/user', 'GET', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  editCurrent(attributes) {
    return new Promise((resolve, reject) => {
      instance.patch('/api/user', {
        data: {
          attributes,
          type: 'user',
        },
      })
        .then(({ data }) => {
          const user = jsonApi.deserialize.resource.call(jsonApi, data.data, data.included);

          resolve(user);
        })
        .catch(() => { reject(); });
    });
  },
};

export default usersAPI;
