import { MOBILE_BREAKPOINT } from '@/constants';

export default {
  namespaced: true,

  state: {
    isMobile: window.innerWidth <= MOBILE_BREAKPOINT,

    // singleSpa
    isMainLoaded: false,
    isMessengerLoaded: false,
  },

  mutations: {
    mainLoaded(state) {
      state.isMainLoaded = true;
    },

    messengerLoaded(state) {
      state.isMessengerLoaded = true;
    },

    actualizeIsMobile(state) {
      state.isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
    },
  },
};
