<template>
  <div class="employment-periods-table-wrapper">
    <table class="employment-periods-table">
      <thead>
        <tr>
          <th class="employment-period-column__start-time">Начало</th>
          <th class="employment-period-column__end-time">Конец</th>
          <th class="employment-period-column__work-factor">Коэффициент</th>
          <th class="employment-period-column__controls"></th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="employmentPeriod in employmentPeriods"
          :key="employmentPeriod.id"
        >
          <td data-test="start-time"
            class="employment-period-column__start-time">
              {{ fromISOToLocaleDateString(employmentPeriod.startAt) }}
            </td>

          <td data-test="end-time"
            class="employment-period-column__end-time">
            {{ fromISOToLocaleDateString(employmentPeriod.endAt) }}
          </td>

          <td class="employment-period-column__work-factor">
            {{ employmentPeriod.workFactor }}
          </td>

          <td class="employment-period-column__controls controls">
            <router-link
              :to="{ name: 'edit-employment-period', params: { id: employmentPeriod.id } }"
            >
              <button class="controls__edit-icon">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#B0BEC5" fill-rule="evenodd">
                    <path d="M12.707.707l1.586 1.586a1 1 0 010 1.414L5
                      13H2v-3L11.293.707a1 1 0 011.414 0zM0 14h16v1a1 1
                      0 01-1 1H1a1 1 0 01-1-1v-1z"/>
                  </g>
                </svg>
              </button>
            </router-link>

            <button
              @click="showConfirmRemove(employmentPeriod.id)"
              class="controls__delete-icon"
            >
              <svg width="15px" height="16px" class="edit-icon" viewBox="0 0 15 16">
                <path fill="#B0BEC5" d="M0.999,4.00074995 L0.999,14.000125 C0.999,15.104056
                  1.895,16 3,16 L11.999,16 C13.105,16 13.999,15.104056
                  13.999,14.000125 L13.999,4.00074995 L11,4.00074995
                  L11,14.000125 L10,14.000125 L10,4.00074995 L8,4.00074995
                  L8,14.000125 L7,14.000125 L7,4.00074995 L5,4.00074995
                  L5,14.000125 L4,14.000125 L4,4.00074995 L0.999,4.00074995
                  Z M5,0 L5,1.00093744 L0.999,1.00093744 C0.448,1.00093744
                  0,1.44890944 0,1.99987501 L0,3.00081245 L15,3.00081245
                  L15,1.99987501 C15,1.44890944 14.552,1.00093744 13.999,
                  1.00093744 L10,1.00093744 L10,0 L5,0 Z">
                </path>
              </svg>
          </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="employment-periods-table-wrapper__btn">
      <router-link :to="{ name: 'add-employment-period' }">
        <vue-button
          :isFlat="false"
          label="Добавить период"
        />
      </router-link>
    </div>

    <confirm-action-modal
      v-if="isShowConfirmRemove"
      body="Удалить рабочий период?"
      @cancel="cancelRemove"
      @confirm="confirmRemove"
    />
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
