<template>
  <div id="header-app">
    <main-header />

    <spinner v-if="isAppLoading" />

    <router-view v-else />

    <notifications group="foo"/>
  </div>
</template>

<script>
/* global System */

import * as SingleSpa from 'single-spa';
import { mapState } from 'vuex';
import { setLocale } from 'yup';
import SseManager from '@/services/events';

import DynamicFavicon from '@/services/dynamic-favicon';

import Header from '@/components/Header/Header.vue';
import Spinner from '@/components/Spinner/Spinner.vue';
import { THEMES, EVENT_WORKSPACE_UPDATE } from '@/constants';

setLocale({
  mixed: {
    required: 'Поле обязательно для заполнения',
  },
  string: {
    email: 'Введите корректный email',
  },
});

export default {
  name: 'app',

  components: {
    'main-header': Header,
    spinner: Spinner,
  },

  computed: {
    ...mapState('ui', ['isMainLoaded', 'isMessengerLoaded']),
    ...mapState('user', ['isAuthenticationCompleted', 'isAuth', 'user']),

    // isMessengerSection() {
    //   return this.$route.path.split('/')[3] === MESSENGER_SECTION;
    // },

    isAppLoading() {
      if (!this.isAuthenticationCompleted) {
        return true;
      }

      if (!this.user.workspace) {
        return false;
      }

      // if (this.isMessengerSection) {
      //   return !this.isMessengerLoaded;
      // }

      return !this.isMainLoaded;
    },
  },

  data() {
    const themes = THEMES;

    return {
      themes,
      dynamicFavicon: new DynamicFavicon(),
    };
  },

  async created() {
    const htmlElement = document.documentElement;
    const activeThemeType = localStorage.getItem('activeThemeType');
    const defaultThemeType = this.themes[0].type;

    htmlElement.setAttribute('theme', activeThemeType || defaultThemeType);

    window.addEventListener('resize', () => {
      this.$store.commit('ui/actualizeIsMobile');
    });

    this.$eventBus.once('user-fetched', () => {
      const sseManager = new SseManager('/sse');
      sseManager.add(EVENT_WORKSPACE_UPDATE, this.onWorkspaceUpdateEvent);

      SingleSpa.registerApplication({
        name: '@teamwork/main',
        app: async () => {
          const bundle = await System.import('@teamwork/main');

          this.$store.commit('ui/mainLoaded');

          return bundle;
        },
        activeWhen: (location) => location.hash.startsWith('#/ws/'),
        customProps: {
          sseManager,
        },
      });

      SingleSpa.registerApplication({
        name: 'mo.tasks',
        app: () => System.import('mo.tasks'),
        activeWhen: (location) => location.hash.startsWith('#/ws/'),
        customProps: {
          sseManager,
        },
      });
    });

    // registerApplication({
    //   name: 'mo.messenger',
    //   app: async () => {
    //     const bundle = await System.import('mo.messenger');
    //
    //     this.$store.commit('ui/messengerLoaded');
    //
    //     return bundle;
    //   },
    //   activeWhen: (location) => (
    //     location.hash.split('/')[1] === 'ws'
    //     && location.hash.split('/')[3] === MESSENGER_SECTION
    //     && this.user.workspace
    //   ),
    //   customProps: {
    //     singleSpaProps: {
    //       accessToken: this.user.meta.access_token,
    //       headerHeight: 70,
    //     },
    //   },
    // });

    SingleSpa.start({
      urlRerouteOnly: true,
    });

    SingleSpa.addErrorHandler((err) => console.error(err));

    // this.setServiceWorkerListeners();
  },

  // beforeRouteUpdate(to, from, next) {
  //   if (this.isMessengerSection) {
  //     this.$store.commit('notification/setMessengerNotificationsCount', 0);
  //   }
  //
  //   next();
  // },

  // watch: {
  //   messengerNotificationsCount() {
  //     this.dynamicFavicon.draw(this.messengerNotificationsCount);
  //   },
  // },

  methods: {
    // setServiceWorkerListeners() {
    //   navigator.serviceWorker.addEventListener('message', (event) => {
    //     if (this.isMessengerSection) {
    //       return;
    //     }
    //
    //     this.$store.commit(
    //       'notification/setMessengerNotificationsCount',
    //       event.data.data.message.chat.unread_messages,
    //     );
    //   });
    // },

    onWorkspaceUpdateEvent({ workspaceId }) {
      if (workspaceId === this.user.workspace.id) {
        this.$store.dispatch('user/getCurrentUser', { include: 'team.users,workspace' });
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/constants.scss';

@media (max-width: 480px) {
  #header-app {
    padding-bottom: 0;
  }
}

html {
  background-color: var(--background-app-color);

  a {
      color: var(--on-surface-anchor-text-color);
      font-weight: 500;
  }
}
</style>
