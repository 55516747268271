<template>
    <div :class="['tabs',
                { 'tabs-custom': customWidth }]">
        <div class="tabs--desktop
                    desktop-section-menu">
            <router-link :to="{
                    name: item.route,
                    params: item.params,
                    query: item.query,
                }"
                class="desktop-section-menu__item"
                v-for="item in items"
                v-bind:key="item.id">
                {{item.title}}
            </router-link>
        </div>

        <div class="tabs--mobile
                    mobile-tabs">
            <div class="mobile-tabs__header"
                 @click.stop="openSectionMenu">
                <router-link :to="{
                        name: item.route,
                        params: item.params,
                        query: item.query,
                    }"
                    class="mobile-tabs__title"
                    v-for="item in items"
                    v-bind:key="item.id">
                    {{item.title}}
                </router-link>

                <span class="mobile-tabs__icon">
                    <svg width="10" height="6" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2l3 4 3-4h2v2L6 6H4L0 2V0z" fill-rule="nonzero"/>
                    </svg>
                </span>
            </div>

            <modal-section-menu class="mobile-tabs__modal"
                                v-if="isVisibleSectionMenu"
                                @close="closeSectionMenu">
                <template v-slot:content>
                    <div class="mobile-section-menu"
                                @click="isVisibleSectionMenu == false">
                        <router-link :to="{
                                name: item.route,
                                params: item.params,
                                query: item.query,
                            }"
                            class="mobile-section-menu__item"
                            v-for="item in items"
                            v-bind:key="item.id">
                            {{item.title}}
                        </router-link>
                    </div>

                    <div class="mobile-section-menu__item
                                mobile-section-menu__item--action-button">
                        <slot name="action-button"></slot>
                    </div>
                </template>
            </modal-section-menu>
        </div>
    </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped src="./index.scss"></style>
