<template>
  <div class="themes-route">
    <personal-themes />

    <router-view />
  </div>
</template>

<script>
import PersonalThemes from '@/components/Profile/PersonalThemes/PersonalThemes.vue';

export default {
  name: 'PersonalThemesView',

  components: {
    'personal-themes': PersonalThemes,
  },
};
</script>
