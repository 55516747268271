<template>
  <div>
    <EmploymentPeriodsTable @successfulRemove="fetchData" />

    <router-view />
  </div>
</template>

<script>
import EmploymentPeriodsTable from '@/components/Profile/EmploymentPeriods/EmploymentPeriodsTable/EmploymentPeriodsTable.vue';

import { EMPLOYMENT_PERIODS_ROUTE_NAME } from '@/constants';

export default {
  components: {
    EmploymentPeriodsTable,
  },

  watch: {
    '$route.name': {
      handler() {
        if (this.$route.name === EMPLOYMENT_PERIODS_ROUTE_NAME) {
          this.fetchData();
        }
      },
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store.dispatch('employmentPeriod/getAll');
    },
  },
};
</script>
